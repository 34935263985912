/**
 * Direttiva che crea l'header visualizzato una volta effettuato il login in modalità amministratore
 */
export interface IHeaderAdminDirectiveScope extends ng.IScope {
	width: number;
	hideMenuButtons: boolean;
	openSidenavMenuAdmin: Function;
	searchContent: string;
	goToMonitoring: Function;
	goToHomeAdmin: Function;
	goToNewsAdmin: Function;
	goToCourses: Function;
	goToUsers: Function;
	goToTemplatingAdmin: Function;
	bgacademyApplicationData: any;
	goToProjects: Function;
	goToGroups: Function;
	goToSubscriptions: Function;
	goToUserMode: Function;
	goToTemplateMailListAdmin: Function;
	goItemListAdmin: Function;
	goWebinarListAdmin: Function;
	isSupervisor: boolean;
	isManager: boolean;
	canManageCourses: boolean;
	canManageTrainings: boolean;
	canManageUsers: boolean;
	canManageGroups: boolean;
	canManageAnnouncements: boolean;
	canManageDashboard: boolean;
	canManageTemplates: boolean;
	canManageItems: boolean;
	goToReportAdmin: Function;
}
angular.module('app').directive("headerAdmin", ($window, $mdSidenav, $state, BgacademyApplicationData, GlobalApplicationData, SessionStorageService) => {
	return {
		link: link,
		restrict: 'AE',
		templateUrl: 'app/shared/headerAdmin/headerAdmin.html'
	};
	function link($scope: IHeaderAdminDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Collegamento all'oggetto principale
		$scope.bgacademyApplicationData = BgacademyApplicationData;

		$scope.isSupervisor = false;
		$scope.isManager = false;
		$scope.canManageCourses = false;
		$scope.canManageTrainings = false;
		$scope.canManageUsers = false;
		$scope.canManageGroups = false;
		$scope.canManageAnnouncements = false;
		$scope.canManageDashboard = false;
		$scope.canManageTemplates = false;
		$scope.canManageItems = false;

		if (GlobalApplicationData.jwtPayload && GlobalApplicationData.jwtPayload.auths && GlobalApplicationData.jwtPayload.auths.length && GlobalApplicationData.jwtPayload.auths.length > 0) {
			let isSupervisor: boolean = false;
			let auths: Array<string> = GlobalApplicationData.jwtPayload.auths;
			angular.forEach(auths, function (auth, authIndex) {
				// Verifico se è supervisore e il relativo diritto
				if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_COURSES) {
					$scope.canManageCourses = true;
					$scope.isSupervisor = true;
				}
				if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_TRAININGS) {
					$scope.canManageTrainings = true;
					$scope.isSupervisor = true;
				}
				if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_USERS) {
					$scope.canManageUsers = true;
					$scope.isSupervisor = true;
				}
				if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_GROUPS) {
					$scope.canManageGroups = true;
					$scope.isSupervisor = true;
				}
				if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_ANNOUNCEMENTS) {
					$scope.canManageAnnouncements = true;
					$scope.isSupervisor = true;
				}
				if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_DASHBOARD) {
					$scope.canManageDashboard = true;
					$scope.isSupervisor = true;
				}
				if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_TEMPLATES) {
					$scope.canManageTemplates = true;
					$scope.isSupervisor = true;
				}
				if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_ITEMS) {
					$scope.canManageItems = true;
					$scope.isSupervisor = true;
				}
			});
			// Sia supervisori che manager utilizzano lo stesso profilo, riesco a differenziarli solo tramite le divisionVisibilities (che ha soltanto il supervisor)
			if ($scope.isSupervisor
				&& !(GlobalApplicationData.jwtPayload.user.userOptions.divisionVisibilities && GlobalApplicationData.jwtPayload.user.userOptions.divisionVisibilities.length)) {
				$scope.isManager = true;
			}
		}

		// Apertura Sidenav di sinistra
		$scope.openSidenavMenuAdmin = () => {
			$mdSidenav('sidenavMenuAdmin').toggle();
		}

		// Porta alla pagina di Home
		$scope.goToHomeAdmin = () => {
			$state.go('app.bgacademyApp.homeAdmin');
		}

		// Porta alla pagina di switch
		$scope.goToUserMode = () => {
			$state.go('app.bgacademyApp.userMode');
		}

		// Porta alla pagina di monitoraggio
		$scope.goToMonitoring = () => {
			$state.go('app.bgacademyApp.monitoringAdmin.users');
		}

		// Porta alla pagina delle news
		$scope.goToNewsAdmin = (startNewSerch: boolean) => {
			if (!startNewSerch) {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count();

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count : 0;

				$state.go('app.bgacademyApp.newsListAdmin', { searchId: count });
			} else {
				$state.go('app.bgacademyApp.newsListAdmin');
			}
		}

		// Porta alla pagina dei corsi
		$scope.goToCourses = (startNewSerch: boolean) => {
			if (!startNewSerch) {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count();

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count : 0;

				$state.go('app.bgacademyApp.courseListAdmin', { searchId: count });
			} else {
				$state.go('app.bgacademyApp.courseListAdmin');
			}
		}


		// Porta alla pagina dei progetti
		$scope.goToProjects = (startNewSerch: boolean) => {
			if (!startNewSerch) {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count();

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count : 0;

				$state.go('app.bgacademyApp.projectListAdmin', { searchId: count });
			} else {
				$state.go('app.bgacademyApp.projectListAdmin');
			}
		}

		// Porta alla pagina con la lista dei template
		$scope.goToTemplateMailListAdmin = (startNewSerch: boolean) => {
			if (!startNewSerch) {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count();

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count : 0;

				$state.go('app.bgacademyApp.templateMailListAdmin', { searchId: count });
			} else {
				$state.go('app.bgacademyApp.templateMailListAdmin');
			}
		}

		// Porta alla pagina con la lista degli item
		$scope.goItemListAdmin = () => {
			$state.go('app.bgacademyApp.itemListAdmin');
		}

		// Porta alla pagina con la lista dei Webinar
		$scope.goWebinarListAdmin = () => {
			$state.go('app.bgacademyApp.webinarListAdmin');
		}

		// Porta alla pagina con la lista dei report
		$scope.goToReportAdmin = () => {
			$state.go('app.bgacademyApp.reportListAdmin');
		}

		// Porta alla pagina delle iscrizioni
		$scope.goToSubscriptions = () => {
			$state.go('app.bgacademyApp.subscriptionListAdmin');
		}

		// Porta alla pagina degli utenti
		$scope.goToUsers = (startNewSerch: boolean) => {
			if (!startNewSerch) {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count("USERS_SEARCH");

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count : 0;

				$state.go('app.bgacademyApp.userListAdmin', { searchId: count });
			} else {
				$state.go('app.bgacademyApp.userListAdmin');
			}
		}

		// Porta alla pagina dei gruppi
		$scope.goToGroups = (startNewSerch: boolean) => {
			if (!startNewSerch) {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count();

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count : 0;

				$state.go('app.bgacademyApp.groupListAdmin', { searchId: count });
			} else {
				$state.go('app.bgacademyApp.groupListAdmin');
			}
		}

		// Porta alla pagina dei template
		$scope.goToTemplatingAdmin = () => {
			$state.go('app.bgacademyApp.templatingAdmin');
		}

		// Traduzione per ricerca contenuto
		$scope.searchContent = 'Ricerca un contenuto';

		// Da 790px in giù, le voci del menu si nascondono lasciando spazio solo all'Hamburge menu
		angular.element($window).bind('resize', () => {
			$scope.width = $window.innerWidth;

			if ($scope.width < 790) {
				$scope.hideMenuButtons = true;
			} else {
				$scope.hideMenuButtons = false;
			}
			$scope.$digest();
		})

		// Stessa cosa al caricamento della pagina
		angular.element(document).ready(() => {
			$scope.width = $window.innerWidth;

			if ($scope.width < 790) {
				$scope.hideMenuButtons = true;
			} else {
				$scope.hideMenuButtons = false;
			}
			$scope.$digest();
		})
	}
});